$default-font-size: 12px;

@mixin regular {
  font-weight: 400;
}

@mixin medium {
  font-weight: 500;
}

@mixin semi-bold {
  font-weight: 600;
}

@mixin font-defaults {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  @include regular();
}

@mixin text($size: 14px) {
  @include font-defaults();
  font-size: $size;
  line-height: $size + 8px;
}

@mixin base-font {
  @include text();
  @include regular();
}

@mixin h1 {
  @include text(38px);
  @include medium();
}

@mixin h2 {
  @include text(30px);
  @include medium();
}

@mixin h3 {
  @include text(24px);
  @include regular();
}

@mixin h4 {
  @include text(20px);
  @include regular();
}

@mixin h5 {
  @include text(18px);
  @include medium();
}