@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('./fonts/Inter-VariableFont_opsz\,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('./fonts/Inter-Italic-VariableFont_opsz,wght.ttf') format('truetype');
}