@import "./colors";
@import "./inter-font";

$defaultFontColor: $grayDark;


@mixin font-defaults {
  font-family: 'Inter', sans-serif;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $slate;
}

@mixin text {
  @include font-defaults();
  font-style: normal;
  font-weight: 400;
}

@mixin text-xsmall {
  @include text();
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  letter-spacing: -0.008em;
}

@mixin text-small {
  @include text();
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.011em;
}

@mixin text-small-bold {
  @include text-small();
  font-weight: 700;
}

@mixin text-medium {
  @include text();
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.011em;
}

@mixin text-medium-bold {
  @include text-medium();
  font-weight: 700;
}

@mixin text-large {
  @include text();
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.011em;
}

@mixin text-large-bold {
  @include text-large();
  font-weight: 700;
}

@mixin text-xlarge {
  @include text();
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 150% */

  letter-spacing: -0.011em;
}

@mixin text-xlarge-bold {
  @include text-xlarge();
  font-weight: 500;
}

// Headings

@mixin headings {
  @include font-defaults();
  font-style: normal;
  font-weight: 500;
  margin-top: 16px;
}

@mixin heading-xl {
  @include headings();
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  /* identical to box height, or 125% */
  letter-spacing: -0.024em;
}

@mixin heading-l {
  @include headings();
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 125% */
  letter-spacing: -0.024em;
}

@mixin heading-m {
  @include headings();
  font-size: 28px;
  line-height: 36px;
  /* identical to box height, or 129% */
  letter-spacing: -0.021em;
}

@mixin heading-s {
  @include headings();
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  letter-spacing: -0.017em;
}

@mixin heading-xs {
  @include headings();
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 140% */
  letter-spacing: -0.017em;
}