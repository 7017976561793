@import "./styles/colors";
@import "./styles/text";
@import "./styles/typography";

body {
  margin: 0;
  @include base-font();
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html,
#root {
  height: 100%;
}

code {
  font-family: 'Fira Code', monospace;
}

@supports (font-variation-settings: normal) {
  code {
    font-family: 'Fira Code VF', monospace;
  }
}

.sd-baggage__notification {
  @include text-large-bold();
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $golden-state;

  &-clear {
    cursor: pointer;
    margin-left: 8px;
    text-decoration: underline;
  }
}